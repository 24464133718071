import React, { useState, useRef, useEffect, useContext } from 'react';
import InputMask from 'react-input-mask';
import { cvv } from "card-validator";
import { CreditCardDataContext } from './CredtCardInput';
import { USER_DATA } from "../../utils/constants/user";
import { useSelector } from 'react-redux';
//TODO: chnange to props
const CVCInput = ({ leaveFieldCallback, focus, tabIndex, setCardError,cardError}) => {

    const [error, setError] = useState(false);
    const [info, setInfo] = useState("");
    const [cvvData, setCVVData] = useState("");
    const userData = useSelector((state) => state.user[USER_DATA]);

    const CardContext = useContext(CreditCardDataContext);
    const inputRef = useRef(null);
    const CVCLengthRequired = CardContext?.cardData?.cvclenght;

    const absLenght = (value) => value.split("").map((item) => parseInt(item))
        .filter((item) => !isNaN(item))
        .length;

    const handleChange = (event) => {
        const CVCvalue = event?.target?.value;
        setCVVData(CVCvalue)
        if (absLenght(CVCvalue) === CVCLengthRequired) {
            setError(false);
            setInfo("");
            setCardError("cvv", "");
            if (leaveFieldCallback) {
                leaveFieldCallback(tabIndex + 1);
            }
            CardContext?.setCardData({
                ...CardContext.cardData,
                cvc: CVCvalue
            });
        } else {
            setError(false);
                setInfo("");
                setCardError("cvv", "");
            CardContext?.setCardData({
                ...CardContext.cardData,
                cvc: null
            });
        }
    }

    const handleBlur = (event) => {
        const CVCvalue = event?.target?.value;
        const CVCverify = cvv(CVCvalue, CVCLengthRequired);
        
        if (CVCverify.isValid) {
            setError(false);
            setInfo("");
            setCardError("cvv", "");
            CardContext?.setCardData({
                ...CardContext.cardData,
                cvc: CVCvalue
            });
        } else {
            setCardError("cvv", "Please enter a valid CVV code.");
            setError(true);
            setInfo("Its not valid CVV code");
            CardContext?.setCardData({
                ...CardContext.cardData,
                cvc: null
            });
        }
    }

    useEffect(() => {
        if (focus) {
            inputRef.current.focus();
        }
    }, [focus])

    useEffect(()=>{
        if(userData && userData.payment_method_data && userData.payment_method_data.cvc){
          setCVVData(userData.payment_method_data.cvc);
        }
      }, [userData])

    return (
        <InputMask
            mask={CVCLengthRequired === 3 ? "999" : "9999"}
            maskChar=" "
            onBlur={handleBlur}
            value={cvvData}
        >
            {() =>
                <input
                    name={`card_cvv_${Math.random()}`}
                    label="CVV"
                    inputMode="numeric"
                    type="text"
                    className={`form-control inputbox ${(error||cardError) ? 'error' :''}`}
                    error={error}
                    placeholder="CVV"
                    onInput={handleChange}
                    required
                    helperText={info}
                    autoFocus={focus}
                    ref={inputRef}
                />
            }
        </ InputMask>
    )
}

export default CVCInput;
// redux/Slice/UserdetailsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const UserdetailsSlice = createSlice({
  name: "Userdetails",
  initialState: { firstName: "", lastName: "", phone: "", email: "" },
  reducers: {
    setUserdetails: (state, action) => action.payload,
    setFirstName(state, action) {
      state.firstName = action.payload;
    },
    setLastName(state, action) {
      state.lastName = action.payload;
    },
    setPhone(state, action) {
      state.phone = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    clearUserdetailsState: () => ({ firstName: "", lastName: "", phone: "", email: "" }),
  },
});

export const {
  setUserdetails,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  clearUserdetailsState,
} = UserdetailsSlice.actions;

export default UserdetailsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const appointmentconfirmationSlice = createSlice({
  name: "appointmentconfirmation",
  initialState: { note: "" },
  reducers: {
    setAppointmentconfirmation: (state, action) => {
      state.note = action.payload;
    },
    clearAppointmentconfirmationState: (state) => {
      return { ...state, note: "" };
    },
  },
});

export const { setAppointmentconfirmation, clearAppointmentconfirmationState } = appointmentconfirmationSlice.actions;
export default appointmentconfirmationSlice.reducer;

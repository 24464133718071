import { axiosPost, axiosGet, axiosPostFileUpload } from "../../services/apiURL";
import { DIETITIAN_DATA, INSURENCE_PROVIDER_LIST, ORGANIZATION_MEMBERS_LIST, SLOT_LIST } from "../../utils/constants/insurence-provider";


export const getInsurenceProviderList = (cb) => {
    return dispatch => {
        return axiosGet(
			{ url: 'master/insurence-providers', reqBody: {} },
			(response) => {
                if(typeof cb == "function") cb(response);
                if(response.data.success){
                    dispatch({ type: INSURENCE_PROVIDER_LIST, payload: response.data.result });
                }
			},
		);
    }
};

export const getOrganizationMembers = (bodyData,cb) => {
    return dispatch => {
        return axiosPost(
			{ url: 'master/organization-members', reqBody: bodyData },
			(response) => {
                if(response.data.success){
                    dispatch({ type: ORGANIZATION_MEMBERS_LIST, payload: response.data.result });
                } else {
                    dispatch({ type: ORGANIZATION_MEMBERS_LIST, payload: [] });
                    dispatch({ type: SLOT_LIST, payload: [] });
                }
                if(typeof cb == "function") cb(response);
			},
		);
    }
};

export const updateOrganizationData = (data) => {
    return { type: ORGANIZATION_MEMBERS_LIST, payload: data };
};

export const getSlotes = (bodyData,cb) => {
    return dispatch => {
        return axiosPost(
			{ url: 'master/available-slots', reqBody: bodyData },
			(response) => {
                if(typeof cb == "function") cb(response);
                if(response.data.success){
                    dispatch({ type: SLOT_LIST, payload: response.data.result });
                } else {
                    dispatch({ type: SLOT_LIST, payload: [] });
                }
			},
		);
    }
};

export const getDietitian = (bodyData,cb) => {
    return dispatch => {
        return axiosPost(
			{ url: 'master/dietitian-data', reqBody: bodyData },
			(response) => {
                if(typeof cb == "function") cb(response);
                if(response.data.success){
                    dispatch({ type: DIETITIAN_DATA, payload: response.data.result });
                }
			},
		);
    }
};

//temp solution
export const clearDietitianData = () => {
    return { type: DIETITIAN_DATA, payload: {} };
  };

export const verifyMemberId = (bodyData,cb) => {
    return dispatch => {
        return axiosPost(
			{ url: 'master/member-verification', reqBody: bodyData },
			(response) => {
                if(typeof cb == "function") cb(response);                
			},
		);
    }
};

export const createPaymentToken = (bodyData,cb) => {
    return dispatch => {
        return axiosPostFileUpload(
			{ url: 'master/create-token', reqBody: bodyData },
			(response) => {
                if(typeof cb == "function") cb(response);                
			},
		);
    }
};
export const updateQuickNotes = (bodyData,cb) => {
    return dispatch => {
        return axiosPostFileUpload(
			{ url: 'master/save-quick-notes', reqBody: bodyData },
			(response) => {
                if(typeof cb == "function") cb(response);                
			},
		);
    }
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: #F0FAF1 !important;
    font-family:"DM Sans",sans-serif ;
}
.pointer{cursor: pointer;}
.fs16{ font-size: 16px;}
.fs14{ font-size: 14px;}
.fs18{font-size: 18px;}
.fs19{font-size: 19.2px;}

.fs22{font-size: 22px;}
.fs24{font-size: 24px;}
.fs20{font-size: 20px;}


@media screen and (max-width:500px) {
    .css-trjzmv-control{
      font-size: 12px !important;
      height: 44px !important;
    }
    .css-1bdnc4p-control{
        font-size: 12px !important;
        height: 44px !important;
    }
    .css-n2taxk-control{
        font-size: 12px !important;
        height: 44px !important;
    }
  }
  @media screen and (max-width:767px) {
    .css-trjzmv-control{
      font-size: 14px;
    }
    .css-1bdnc4p-control{
        font-size: 14px !important;
    }
    .css-n2taxk-control{
        font-size: 14px !important;
    }
  }

  .css-1qrxvr1-singleValue{
    color: var(--bs-body-color) !important;
  }
  .fix-height-timer{
    min-height: 70px;
    height: 100%;
  }`, "",{"version":3,"sources":["webpack://./src/css/commonStyles.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,iCAAiC;AACrC;AACA,SAAS,eAAe,CAAC;AACzB,OAAO,eAAe,CAAC;AACvB,OAAO,eAAe,CAAC;AACvB,MAAM,eAAe,CAAC;AACtB,MAAM,iBAAiB,CAAC;;AAExB,MAAM,eAAe,CAAC;AACtB,MAAM,eAAe,CAAC;AACtB,MAAM,eAAe,CAAC;;;AAGtB;IACI;MACE,0BAA0B;MAC1B,uBAAuB;IACzB;IACA;QACI,0BAA0B;QAC1B,uBAAuB;IAC3B;IACA;QACI,0BAA0B;QAC1B,uBAAuB;IAC3B;EACF;EACA;IACE;MACE,eAAe;IACjB;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,0BAA0B;IAC9B;EACF;;EAEA;IACE,sCAAsC;EACxC;EACA;IACE,gBAAgB;IAChB,YAAY;EACd","sourcesContent":["body {\n    background-color: #F0FAF1 !important;\n    font-family:\"DM Sans\",sans-serif ;\n}\n.pointer{cursor: pointer;}\n.fs16{ font-size: 16px;}\n.fs14{ font-size: 14px;}\n.fs18{font-size: 18px;}\n.fs19{font-size: 19.2px;}\n\n.fs22{font-size: 22px;}\n.fs24{font-size: 24px;}\n.fs20{font-size: 20px;}\n\n\n@media screen and (max-width:500px) {\n    .css-trjzmv-control{\n      font-size: 12px !important;\n      height: 44px !important;\n    }\n    .css-1bdnc4p-control{\n        font-size: 12px !important;\n        height: 44px !important;\n    }\n    .css-n2taxk-control{\n        font-size: 12px !important;\n        height: 44px !important;\n    }\n  }\n  @media screen and (max-width:767px) {\n    .css-trjzmv-control{\n      font-size: 14px;\n    }\n    .css-1bdnc4p-control{\n        font-size: 14px !important;\n    }\n    .css-n2taxk-control{\n        font-size: 14px !important;\n    }\n  }\n\n  .css-1qrxvr1-singleValue{\n    color: var(--bs-body-color) !important;\n  }\n  .fix-height-timer{\n    min-height: 70px;\n    height: 100%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

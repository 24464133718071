import { createSlice } from "@reduxjs/toolkit";
// const initialState = {
//   firstName: "",
//   lastName: "",
//   memberID: "",
//   addressLine1: "",
//   addressLine2: "",
//   primaryaddressLine1: "",
//   primaryaddressLine2: "",
//   primaryzipcode: "",
//   primarycity: "",
//   primarystate: "",
//   city: "",
//   phone: "",
//   email: "",
//   state: "",
//   zipCode: "",
//   selectedDateOfBirth: {
//     day: '',
//     month: '',
//     year: '',
//   },
//   firstnameprimary: "",
//   lastnameprimary: "",
//   birthSex: "",
//   selectedPrimaryPolicyholderOption: null,
//   selectedGender: null,
// };
const insuranceinformationSlice = createSlice({
  name: "insuranceinformation",
  initialState: {
    firstName: "",
    lastName: "",
    firstnameprimary: "",
    lastnameprimary: "",
    memberID: "",
    primaryaddressLine1: "",
    primaryaddressLine2: "",
    primaryzipcode: "",
    primarycity: "",
    primarystate: "",
    addressLine1: "",
    addressLine2: "",
    isSameAsPrimary:false,
    zipcode: "",
    city: "",
    selectedDateOfBirth: {
      day: '',
      month: '',
      year: '',
    },
    primaryphone: "",
    phone: "",
    email: "",
    selectedPrimaryPolicyholderOption: null,
    state: "",
    selectedGender: null,
    birthSex: "",
  },
  reducers: {
    setInsuranceinformation: (state, action) => action.payload,
    setMemberID(state, action) {
      state.memberID = action.payload;
    },
    setSelectedGender(state, action) {
      state.selectedGender = action.payload;
    },
    setSameAsPrimary(state, action) {
      state.isSameAsPrimary = action.payload;
    },
    setSelectedPrimaryPolicyholderOption(state, action) {
      state.selectedPrimaryPolicyholderOption = action.payload;
    },
    setaddressLine1(state, action) {
      state.addressLine1 = action.payload;
    },
    setaddressLine2(state, action) {
      state.addressLine2 = action.payload;
    },
    setSelectedDateOfBirth(state, action) {
      state.selectedDateOfBirth = action.payload;
    },
    setDay(state, action) {
      state.day = action.payload
    },
    setMonth(state, action) {
      state.month = action.payload
    },
    setYear(state, action) {
      state.year = action.payload
    },
    setcity(state, action) {
      state.city = action.payload;
    },
    setstate(state, action) {
      state.state = action.payload;
    },
    setzipCode(state, action) {
      state.zipcode = action.payload;
    },
    setFirstName(state, action) {
      state.firstName = action.payload;
    },
    setFirstNamePrimary(state, action) {
      state.firstnameprimary = action.payload;
    },
    setLastNamePrimary(state, action) {
      state.lastnameprimary = action.payload;
    },
    setLastName(state, action) {
      state.lastName = action.payload;
    },
    setPhone(state, action) {
      state.phone = action.payload;
    },
    setPrimaryPhone(state, action) {
      state.primaryphone = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setGender(state, action) {
      state.gender = action.payload;
    },
    setbirthSex(state, action) {
      state.birthSex = action.payload;
    },
    setPrimaryaddressLine1(state, action) {
      state.primaryaddressLine1 = action.payload;
    },
    setPrimaryaddressLine2(state, action) {
      state.primaryaddressLine2 = action.payload;
    },
    setPrimarycity(state, action) {
      state.primarycity = action.payload;
    },
    setPrimarystate(state, action) {
      state.primarystate = action.payload;
    },
    setPrimaryzipCode(state, action) {
      state.primaryzipcode = action.payload;
    },
    clearinsuranceinformationState: () => ({ setMemberID: "", setSelectedDateOfBirth: "", setPrimaryPhone: "", setPolicyHolder: "", setbirthSex: "", setDay: "", setSelectedPrimaryPolicyholderOption: "", setMonth: "", setYear: "", setaddressLine1: "", setaddressLine2: "", setFirstName: "", setLastName: "", setcity: "", setstate: "", setzipCode: "", setPhone: "", setEmail: "", setSelectedGender: "", setFirstNamePrimary: "", setLastNamePrimary: "",setPrimaryaddressLine1:"" ,setPrimaryaddressLine2:"", setPrimarycity:"",setPrimarystate:"",setPrimaryzipCode:""}),

  },
});

export const {
  setInsuranceinformation,
  setMemberID,
  setPolicyHolder,
  setPrimaryPhone,
  setDay,
  setMonth,
  setYear,
  setaddress,
  setFirstName,
  setLastName,
  setPhone,
  setEmail,
  setSelectedDateOfBirth,
  setcity,
  setbirthSex,
  setzipCode,
  setstate,
  setaddressLine1,
  setaddressLine2,
  setFirstNamePrimary,
  setLastNamePrimary,
  clearinsuranceinformationState,
  setSelectedGender,
  setPrimaryaddressLine1,
  setPrimaryaddressLine2,
  setPrimarycity,
  setPrimarystate,
  setPrimaryzipCode,
  setSelectedPrimaryPolicyholderOption,
  setSameAsPrimary,
} = insuranceinformationSlice.actions;

export default insuranceinformationSlice.reducer;

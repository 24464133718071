import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import '../css/Progressbar.css';
import '../css/welcome.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Youreeligible = () => {
  const progressValue = 80;
  const navigate = useNavigate();

  const handleSelectButton = () => {
    navigate('/CreditCardInfo');
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
    });    
  }, []);

  return (
    <>
      <Header progressValue={progressValue} />
      <div  className="container page-main1 insurance2-margin" data-aos="zoom-in">
        <div className='col-lg-5 col-md-8 col-sm-12 col-12 mx-auto text-center mx-auto Youreeligible' >
          <h3 className='mt-4'>Your insurance information could not be verified.</h3>
          <p className='mt-4'>Everlong’s services are 100% covered by insurance for 95% of patients. If your plan happens to have exclusions for our services, you <b>will not</b> be charged for any sessions that have already occurred as long as you selected your correct plan upon booking (ex. PPO vs HMO). You will only be responsible for payment if you have a copay or coinsurance.</p>
        </div>
        <div className="mt-3">
          <button
            id="nextButton"
            type="button"
            className="next-button"
            onClick={handleSelectButton}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
};

export default Youreeligible;

import { createSlice } from '@reduxjs/toolkit';

const dateOfBirthSlice = createSlice({
  name: 'dateOfBirth',
  initialState: {day: '', month: '', year: ''},
  reducers: {
    setDateOfBirth: (state, action) => action.payload,
    setDay(state, action){
      state.day = action.payload
    },
    setMonth(state, action){
      state.month = action.payload
    },
    setYear(state, action){
      state.year = action.payload
    },
    cleardateOfBirthState: () => ({ setDay: "", setMonth: "", setYear: ""}),

  },
});

export const { setDateOfBirth,setDay , setMonth, setYear,cleardateOfBirthState} = dateOfBirthSlice.actions;

export default dateOfBirthSlice.reducer;

import moment from 'moment';
/*export const createTimeSlotObject = (dateObj) =>{
    let timeObj = {
        morningSlots: [],
        afternoonSlots: [],
        eveningSlots: [],
    }
    
    if(dateObj && Array.isArray(dateObj) && dateObj.length > 0){
        for(let dt of dateObj){
            let date = new Date(moment(dt.date, 'YYYY-MM-DD HH:mm:ss Z'));
            let getHours = date.getHours();
            if(getHours >= 8 &&  getHours < 12){
                timeObj.morningSlots.push(dt)
            }else if(getHours >= 12 && getHours < 17){
                timeObj.afternoonSlots.push(dt)
            }else{
                timeObj.eveningSlots.push(dt)
            }
        }
    }

    return timeObj;
}  */

export const createTimeSlotObject = (dateObj) => {
    let timeObj = {
      morningSlots: [],
      afternoonSlots: [],
      eveningSlots: [],
    };
  
    if (dateObj && Array.isArray(dateObj) && dateObj.length > 0) {
      for (let dt of dateObj) {
        let date = new Date(moment(dt.date, 'YYYY-MM-DD HH:mm:ss Z'));
        let getHours = date.getHours();
        if (getHours >= 0 && getHours < 12) {
          timeObj.morningSlots.push(dt);
        } else if (getHours >= 12 && getHours < 17) {
          timeObj.afternoonSlots.push(dt);
        } else {
          timeObj.eveningSlots.push(dt);
        }
      }
    }
  
    return timeObj;
  };
  

export function dateFormate(date) {
    const formattedDate = moment(date, 'YYYY-MM-DD HH:mm:ss Z').format('ddd, MMM D, YYYY');
    /*const dateObj = new Date(date);
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const monthsOfYear = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const formattedDate = `${daysOfWeek[dateObj.getDay()]}, ${monthsOfYear[dateObj.getMonth()]
      } ${dateObj.getDate()}, ${dateObj.getFullYear()}`;*/
    return formattedDate;
  }

  export const isValidPhone = (mobileNo) => {
    mobileNo =mobileNo && mobileNo?.toString()
    const mobilePattern = /^\(\d{3}\) \d{3}-\d{4}$/;
    return mobilePattern.test(mobileNo);
  };

  // export function isValidEmail(email) {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return emailRegex.test(email);
  // }

  export const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
import React from 'react';
import '../css/spinner.css'; // Import your CSS or SCSS file for styling the spinner
import { BeatLoader } from 'react-spinners'; 

const Spinner = () => {
    return (
      <BeatLoader color="#79ba39" />
    );
  };
  
  export default Spinner;
  
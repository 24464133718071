import { axiosGet } from "../../services/apiURL";
import { STATE_LIST } from "../../utils/constants/state";


export const getStateList = (cb) => {
    return dispatch => {
        return axiosGet(
			{ url: 'master/state-list', reqBody: {} },
			(response) => {
                if(typeof cb == "function") cb(response);
                if(response.data.success){
                    dispatch({ type: STATE_LIST, payload: response.data.result });
                }
			},
		);
    }
};
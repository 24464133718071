import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useCookies } from "react-cookie";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import { defaultStyles } from "../utils/helpers/SelectCustoms";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import {
  getUserData,
  updateUserData,
} from "../redux/action/userAction";
import { getInsurenceProviderList } from "../redux/action/insurenceProviderAction";
import { INSURENCE_PROVIDER_LIST } from "../utils/constants/insurence-provider";

const InsuranceSelection = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const progressValue = 20;
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const insurenceProviderList = useSelector((state) =>
    state.insurence[INSURENCE_PROVIDER_LIST]?.map((ins) => {
      return {
        id: ins.id,
        value: ins.payer_id,
        label: ins.payer_name,
      };
    })
  );
  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const [selectedInsurance, setInsurance] = useState({});
  const [selectedState, setSelectStates] = useState({});
  const [isAcceptedCondition, setIsAcceptedCondition] = useState(false);
  const setUserDataHandler = (uData) => {
    if (uData.insurence && uData.insurence.insurance_provider_id) {
      setInsurance({
        id: uData.insurence.id,
        value: uData.insurence.insurance_provider_id,
        label: uData.insurence.insurance_provider_name,
      });
    } else {
      setInsurance({});
    }

    if (uData?.insurence?.check_insurance_consent) {
      setIsAcceptedCondition(!!uData?.insurence.check_insurance_consent);
    }

    if (uData.state) {
      setSelectStates(uData.state);
    } else {
      setSelectStates({});
    }
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            if (
              !insurenceProviderList ||
              (Array.isArray(insurenceProviderList) &&
                insurenceProviderList.length === 0)
            ) {
              dispatch(getInsurenceProviderList());
            }
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/insuranceselection",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const handleSelectChange = (selectedOption) => {
    if (
      selectedOption &&
      selectedOption.label &&
      (selectedOption.label.includes("HMO") ||
        selectedOption.label.includes("Cigna Local Plus"))
    ) {
      if (selectedState && selectedState.value !== "MA") {
        setErrorMessage(
          "Everlong's services are currently not covered by HMO plans in your state. You may proceed with Private Pay or HSA / FSA."
        );
      }
    } else if (selectedOption.value === "97213") {
      setErrorMessage(
        "Everlong currently doesn't accept Medicaid. You may proceed with Private Pay or HSA / FSA."
      );
    } else if (selectedOption.value === "97214") {
      setErrorMessage(
        "Everlong currently doesn't accept Medicare but will soon. You may proceed with Private Pay or HSA / FSA."
      );
    } else {
      setErrorMessage("");
    }
    console.log("selectedOption", selectedOption);
    setInsurance(selectedOption);
    setIsAcceptedCondition(false);
    setError(false);
  };

  const handleSelectButton = () => {
    if (selectedInsurance.value) {
      if (selectedInsurance.value === "04271" && !isAcceptedCondition) {
        setError("Please acknowledge the referral requirement.");
        return;
      }
      if (
        selectedInsurance &&
        selectedInsurance.label &&
        (selectedInsurance.label.includes("HMO") ||
          selectedInsurance.label.includes("Cigna Local Plus"))
      ) {
        if (selectedState && selectedState.value !== "MA") {
          setErrorMessage(
            "Everlong's services are currently not covered by HMO plans in your state. You may proceed with Private Pay or HSA / FSA."
          );
          return;
        }
      }
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          insurence: {
            id: selectedInsurance.id,
            insurance_provider_id: selectedInsurance?.value,
            insurance_provider_name: selectedInsurance?.label,
            check_insurance_consent: isAcceptedCondition,
          },
        })
      );
      setErrorMessage("");
      setError(false);
      navigate("/dateofbirth");
    } else {
      setError("Please select your insurance provider.");
    }
  };

  const handleCheckboxChange = (e) => {
    setIsAcceptedCondition(e.target.checked);
    setError(false);
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <Header progressValue={progressValue} backRoute={"/selectstates"} />

      <div className="container page-main1 mt-0">
        <div className="row">
          <div
            className="col-lg-12 col-md-8 col-sm-12  text-left mx-auto mt-5"
            data-aos="zoom-out"
          >
            <h3 className="mt-4 text-center">
              Select your health insurance provider
            </h3>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-lg-12 col-md-8 col-sm-12  text-left mx-auto "
            data-aos="zoom-out"
          >
            <p className=" text-center">
              95% of our patients are 100% covered with $0 copay.
            </p>
          </div>
        </div>
        <div
          className="col-lg-5 col-md-8 col-sm-12 mt-3 col-10 mx-auto insuranceSelect"
          data-aos="zoom-out"
        >
          <Select
            options={insurenceProviderList}
            value={selectedInsurance}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.id}
            placeholder=""
            onChange={handleSelectChange}
            isSearchable
            noOptionsMessage={() => "No choices"}
            styles={defaultStyles}
          />

          {selectedInsurance &&
            (selectedInsurance.label === "Private Pay" ||
              selectedInsurance.label === "HSA / FSA") && (
              <div className="row">
                <div
                  className="col-lg-12 col-md-8 col-sm-12 text-left mx-auto"
                  data-aos-delay="100"
                  data-aos="zoom-out"
                >
                  <p className="mt-5 text-center">
                    Cost per session for Private Pay or HSA / FSA is $150
                  </p>
                </div>
              </div>
            )}
        </div>
        <div
          className="col-xl-6 col-lg-7 col-md-8 col-sm-12  col-10 mx-auto"
          data-aos="zoom-out"
        >
          {selectedInsurance && selectedInsurance.value === "04271" && (
            <div className="mt-5 main-check-select">
              <div>
                <input
                  type="checkbox"
                  className={`check-hearby ${error ? "error" : ""}`}
                  checked={isAcceptedCondition}
                  onChange={handleCheckboxChange}
                />
              </div>
              <label className="checkbox-label-info">
                Your insurance company requires a referral to see a registered
                dietitian. You acknowledge that you will upload a referral to
                your patient portal or reschedule your appointment at least 24
                hours in advance and that failure to do so will result in a
                no-show/late-cancel fee. If needed, download our{" "}
                <a
                  className="link-with-a"
                  href="/src/PatientReferralForm.pdf"
                  download
                >
                  Patient Referral Form
                </a>
                .
              </label>
            </div>
          )}
          {error && (
            <p className="mt-3 text-center" style={{ color: "red" }}>
              {error ? error : "Please select your insurance provider."}
            </p>
          )}
          {errorMessage && (
            <p className="mt-2" style={{ color: "red", textAlign: "center" }}>
              {errorMessage}
            </p>
          )}
          {!errorMessage && (
            <div className="mt-5">
              <button
                id="nextButton"
                type="button"
                className="next-button"
                onClick={handleSelectButton}
              >
                Continue
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default InsuranceSelection;

import { configureStore } from "@reduxjs/toolkit";
import insuranceReducer from "./Slice/insuranceSlice";
import welcomeReducer from "./Slice/welcomeSlice";
import selectStateReducer from './Slice/SelectStatesSlice'
import dateOfBirthReducer from "./Slice/dateOfBirthSlice ";
import UserdetailsReducer from "./Slice/UserdetailsSlice";
import UserdetailsReducer2 from "./Slice/UserdetailsSlice2";

// import referredReducer from "./Slice/referredSlice";
import insuranceinformationReducer from "./Slice/insuranceinformationSlice";
import CreditCardInfoReducer from "./Slice/CreditCardInfoSlice";
import appointmentconfirmationReducer from "./Slice/appointmentconfirmationSlice";
import countdownReducer from "./Slice/countdownSlice";
import AppointmentBooking from "./Slice/appoinmentBookingSlice";

import healthConcerns from "./reducers/health-concerns";
import user from "./reducers/user";
import state from "./reducers/state";
import insurenceProvider from "./reducers/insurence-provider";

const store = configureStore({
  reducer: {
    insurance: insuranceReducer,
    welcome: welcomeReducer,
    SelectStates: selectStateReducer,
    dateOfBirth: dateOfBirthReducer, 
    Userdetails:UserdetailsReducer,
    Userdetails2:UserdetailsReducer2,
    // referred: referredReducer,
    insuranceinformation: insuranceinformationReducer,
    CreditCardInfo:CreditCardInfoReducer,
    appointmentconfirmation: appointmentconfirmationReducer,
    countdown: countdownReducer,
    AppointmentBooking: AppointmentBooking,

    healthConcerns:healthConcerns,
    user:user,
    state: state,
    insurence:insurenceProvider
  },
});

export default store;

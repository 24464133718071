import React from 'react'
import { Provider } from 'react-redux';
import store from './redux/store';
import Welcome from "./components/welcome"
import SelectStates from "./components/SelectStates"
import InsuranceSelection from "./components/InsuranceSelection"
import DateofBirth from "./components/DateofBirth"
import Youreeligible from "./components/Youreeligible"
import Userdetails from "./components/Userdetails"
import Userdetails2 from "./components/Userdetails2"
import AppointmentBooking from "./components/AppointmentBooking"
import Referred from "./components/Referred"
import InsuranceInformation from "./components/InsuranceInformation"
import InsuranceInformation2 from "./components/InsuranceInformation2"
import Appointmentconfirmation from "./components/Appointmentconfirmation"
import CreditCardInfo from "./components/CreditCardInfo"
import NoServiceState from "./components/NoServiceState"
import DietitianScreen from "./components/DietitianScreen";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/css/commonStyles.css";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-PHZHDK5'
}
TagManager.initialize(tagManagerArgs)

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/selectstates" element={<SelectStates />} />
          <Route path="/insuranceselection" element={<InsuranceSelection />} />
          <Route path="/dateofbirth" element={<DateofBirth />} />
          <Route path="/youreeligible" element={<Youreeligible />} />
          <Route path="/userdetails" element={<Userdetails />} />
          <Route path="/userdetails2" element={<Userdetails2 />} />
          <Route path="/noservicestate" element={<NoServiceState />} />
          <Route path="/appointmentbooking" element={<AppointmentBooking />} />
          <Route path="/dietitian" element={<DietitianScreen />} />
          <Route path="/referred" element={<Referred />} />
          <Route path="/insuranceinformation" element={<InsuranceInformation />} />
          <Route path="/insuranceinformation2" element={<InsuranceInformation2 />} />
          <Route path="/creditcardinfo" element={<CreditCardInfo />} />
          <Route path="/appointmentconfirmation" element={<Appointmentconfirmation />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}

export default App

import { createSlice } from "@reduxjs/toolkit";

const welcomeSlice = createSlice({
  name: 'welcome',
  initialState: {
    selectedOption: null,
    selectedSubOption: null,
    otherInput: '',
    showErrorMessage: false,
  },
  reducers: {
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setSelectedSubOption: (state, action) => {
      state.selectedSubOption = action.payload;
    },
    setOtherInput: (state, action) => {
      state.otherInput = action.payload;
    },
    setShowErrorMessage: (state, action) => {
      state.showErrorMessage = action.payload;
    },
    clearWelcomeState: (state) => {
      state.selectedOption = null;
      state.selectedSubOption = null;
      state.otherInput = '';
      state.showErrorMessage = false;
    },
  },
});

export const {
  setSelectedOption,
  setSelectedSubOption,
  setOtherInput,
  setShowErrorMessage,
  clearWelcomeState
} = welcomeSlice.actions;

export default welcomeSlice.reducer;


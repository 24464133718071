import React, { useEffect } from 'react';
import Header from './header';
import '../css/Progressbar.css';
import '../css/welcome.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const NoServiceState = () => {
  const progressValue = 10;



  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: 'ease-in-out',
    });
  }, []);

  return (
    <>
      <Header progressValue={progressValue} />
      <div style={{height:'69vh'}} className="container page-main1" data-aos="zoom-in">
        <div className='col-lg-6 col-md-8 col-sm-12 col-12 mx-auto text-center mx-auto NoServiceState' >
          <h3>Hold tight! We’re not yet licensed in your state.</h3>
          <p className='mt-4'>Our team is constantly growing and expanding, and we’re quickly adding states. To have priority booking and be among the first to know when Everlong is available in your state, please <a href="https://form.jotform.com/232076932215150" target="_blank" rel="noopener noreferrer">click here</a> to submit your request.</p>
        </div>
      </div>
    </>
  );
};

export default NoServiceState;

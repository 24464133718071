import React, { useMemo, useState } from "react";
import "./DititianBlock.css";
import "../../css/AppointmentBooking.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

// Slick settings for auto-scrolling
const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000, // Adjust the interval as needed
};

const DititianBlock = ({ generateSlotButton, slotList, excellentMatch }) => {
  const [availableSlot, setAvailableSlot] = useState([]);
  const [hideMore, setHideMore] = useState(true);
  const [expandedStates, setExpandedStates] = useState(
    Array(slotList?.reviews.length).fill(false)
  );

  const toggleExpand = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  useMemo(() => {
    let tempSlot = slotList?.slots || [];
    setAvailableSlot(tempSlot);
  }, [slotList]);

  return (
    <div className="dititian-blk">
      <div className="mb-3 d-flex flex-column  blk-border">
        {/* Excellent match  */}
        {excellentMatch && (
          <div className="excellent-div mb-3">
            <span>Excellent match</span>
          </div>
        )}
        <div className="d-flex flex-row gap-3 mb-4 ">
          <div className="img-section">
            {slotList?.dietitian?.dietitian_image && (
              <img
                className="dietitian-img"
                src={slotList?.dietitian?.dietitian_image}
                alt="dietitian-img"
              />
            )}
          </div>
          <div className="name-section">
            <h4 className="mb-2">{slotList?.dietitian?.name || ""}</h4>
            <span>{slotList?.dietitian?.designation || "-"}</span>
          </div>
        </div>
        {/* availability section  */}
        {availableSlot.length > 0 && (
          <div className="mb-3">
            <p className="mb-1">
              <strong>Availability</strong>
            </p>
            <div className="availability-grid slots">
              {availableSlot.length > 0 &&
                generateSlotButton(
                  hideMore ? availableSlot.slice(0, 8) : availableSlot,
                  "morning",
                  slotList
                )}
            </div>
            {availableSlot.length > 8 && (
              <div className="row">
                <div
                  className="col-12 cursor-pointer text-center "
                  onClick={() => setHideMore(!hideMore)}
                >
                  {hideMore ? "See more" : "See less"}
                </div>
              </div>
            )}
          </div>
        )}

        {/* for  slider section  */}
        <div className="mb-4">
          <p className="mb-0">
            <strong>Reviews</strong>
          </p>
          {slotList?.reviews?.length > 0 && (
            <div className="auto-scroll-slider">
              <Slider {...sliderSettings}>
                {slotList?.reviews.map((item, index) => (
                  <div key={item.id} className="slider-item">
                    <p>
                    {expandedStates[index]
              ? item.review_text
              : item.review_text.length > 255
              ? item.review_text.slice(0, 255) + "..."
              : item.review_text}
                      {item.review_text.length > 255 && (
                        <span
                          className="cursor-pointer ms-2 text-theme-green"
                          onClick={() => toggleExpand(index)}
                        >
                          {expandedStates[index] ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                          ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                          )}
                        </span>
                      )}
                    </p>
                    <strong className="author">{item.author_name || ""}</strong>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DititianBlock;

import { HEALTH_CONCERN_LIST } from "../../utils/constants/health-concern";


const INIT_STATE = {
    HEALTH_CONCERN_LIST:[]
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case HEALTH_CONCERN_LIST: {
            return {
                ...state,
                HEALTH_CONCERN_LIST: action.payload,
            }
        }
        
        default: {
            return state;
        }
    }
};

export default reducerFunc;

import { createSlice } from '@reduxjs/toolkit';

const insuranceSlice = createSlice({
  name: 'insurance',
  initialState: null,
  reducers: {
    setInsurance: (state, action) => action.payload,
    clearinsuranceState: () => null,
  },
});

export const { setInsurance,clearinsuranceState } = insuranceSlice.actions;

export default insuranceSlice.reducer;


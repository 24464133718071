import { createSlice } from "@reduxjs/toolkit";

const CreditCardInfoSlice = createSlice({
  name: "CreditCardInfo",
  initialState: {
    firstName: "",
    lastName: "",
    card: {
      number: "",
      exDate: "",
      cvc: "",
    },
    frontInsuranceCardImageUrl: null,
    backInsuranceCardImageUrl:null,
  },
  reducers: {
    setCreditCardInfo: (state, action) => action.payload,
    setFirstName(state, action) {
      state.firstName = action.payload;
    },
    setLastName(state, action) {
      state.lastName = action.payload;
    },
    setNumber(state, action) {
      state.card.number = action.payload;
    },
    setExDate(state, action) {
      state.card.exDate = action.payload;
    },
    setCvc(state, action) {
      state.card.cvc = action.payload;
    },
    setFrontInsuranceCardImageUrl: (state, action) => {
      state.frontInsuranceCardImageUrl = action.payload;
    },
    setBackInsuranceCardImageUrl: (state, action) => {
      state.backInsuranceCardImageUrl = action.payload;
    },
    clearCreditCardInfoState: () => ({ firstName: "",
    lastName: "",
    card: {
      number: "",
      exDate: "",
      cvc: "",
    },
    insuranceCardImageUrl: null,
  }),


  },
});

export const { setCreditCardInfo, setFirstName, setLastName, setNumber, setExDate, setCvc, setFrontInsuranceCardImageUrl,setBackInsuranceCardImageUrl, clearCreditCardInfoState } =
  CreditCardInfoSlice.actions;

export default CreditCardInfoSlice.reducer;

import { axiosPost } from "../../services/apiURL";
import { USER_DATA } from "../../utils/constants/user";

export const getUserData = (postData,cb) => {
    return dispatch => {
        return axiosPost(
			{ url: 'user/evuid-data', reqBody: postData },
			(response) => {
                if(typeof cb == "function") cb(response);
                if(response.data.success){
                    dispatch({ type: USER_DATA, payload: response.data.result });
                }
			},
		);
    }
};

export const createUserData = (postData,cb) => {
    return dispatch => {
        return axiosPost(
			{ url: 'user/create', reqBody: postData },
			(response) => {
                if(typeof cb == "function") cb(response);
                if(response.data.success){
                    dispatch({ type: USER_DATA, payload: response.data.result });
                }
			},
		);
    }
};

export const updateUserData = (postData,cb) => {
    return dispatch => {
        return axiosPost(
			{ url: 'user/update', reqBody: postData },
			(response) => {
                if(typeof cb == "function") cb(response);
                if(response.data.success){
                    // dispatch({ type: USER_DATA, payload: response.data.result });
                }
			},
		);
    }
};
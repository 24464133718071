// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputbox{
    line-height: 2.5;
    
}
.error input.inputbox {
    border-color: red;
  }

  .error-select {
    border: 1px solid red; 
    border-radius: 4px;
  }
  
  .inputbox.selected {
    border: 2px solid #79ba39 ;
    box-shadow: none;
  }
  
  .page-main1 {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    /* height: 79vh; */
    /* height: 100vh; */
    
    margin-top: 100px;
  }
  .usr2-margin{
    margin-top: 10.5rem;
    height: auto;
  }
  .insurance2-margin{
    margin-top: 13.5rem;
    height: auto;
  }

  .top-size-space{
    margin-top: 100px;

  }`, "",{"version":3,"sources":["webpack://./src/css/Userdetails.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;;AAEpB;AACA;IACI,iBAAiB;EACnB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,0BAA0B;IAC1B,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB,EAAE,4BAA4B;IACrD,mBAAmB,EAAE,8BAA8B;IACnD,kBAAkB;IAClB,mBAAmB;;IAEnB,iBAAiB;EACnB;EACA;IACE,mBAAmB;IACnB,YAAY;EACd;EACA;IACE,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,iBAAiB;;EAEnB","sourcesContent":[".inputbox{\n    line-height: 2.5;\n    \n}\n.error input.inputbox {\n    border-color: red;\n  }\n\n  .error-select {\n    border: 1px solid red; \n    border-radius: 4px;\n  }\n  \n  .inputbox.selected {\n    border: 2px solid #79ba39 ;\n    box-shadow: none;\n  }\n  \n  .page-main1 {\n    display: flex;\n    flex-direction: column;\n    justify-content: center; /* Center items vertically */\n    align-items: center; /* Center items horizontally */\n    /* height: 79vh; */\n    /* height: 100vh; */\n    \n    margin-top: 100px;\n  }\n  .usr2-margin{\n    margin-top: 10.5rem;\n    height: auto;\n  }\n  .insurance2-margin{\n    margin-top: 13.5rem;\n    height: auto;\n  }\n\n  .top-size-space{\n    margin-top: 100px;\n\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.custom-upload-button {
    background-color: transparent; /* Transparent background */
    color: #9ac96c; /* Text color */
    border: 1px solid #9ac96c; /* Border color */
    border-radius: 10px; /* Border radius */
    padding: 16px 60px; /* Padding */
    transition: all 0.2s ease-in-out; /* Transition */
}

/* Custom button styles on hover */
.custom-upload-button:hover {
    background-color: #9ac96c; /* Background color on hover */
    color: #fff; /* Text color on hover */
}


.file-name {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  
  .remove-icon {
    margin-left: 10px;
    cursor: pointer;
    font-size: 18px;
    color: #333333d4;
  }`, "",{"version":3,"sources":["webpack://./src/css/AppointmentConfirmation.css"],"names":[],"mappings":";AACA;IACI,6BAA6B,EAAE,2BAA2B;IAC1D,cAAc,EAAE,eAAe;IAC/B,yBAAyB,EAAE,iBAAiB;IAC5C,mBAAmB,EAAE,kBAAkB;IACvC,kBAAkB,EAAE,YAAY;IAChC,gCAAgC,EAAE,eAAe;AACrD;;AAEA,kCAAkC;AAClC;IACI,yBAAyB,EAAE,8BAA8B;IACzD,WAAW,EAAE,wBAAwB;AACzC;;;AAGA;IACI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,eAAe;IACf,gBAAgB;EAClB","sourcesContent":["\n.custom-upload-button {\n    background-color: transparent; /* Transparent background */\n    color: #9ac96c; /* Text color */\n    border: 1px solid #9ac96c; /* Border color */\n    border-radius: 10px; /* Border radius */\n    padding: 16px 60px; /* Padding */\n    transition: all 0.2s ease-in-out; /* Transition */\n}\n\n/* Custom button styles on hover */\n.custom-upload-button:hover {\n    background-color: #9ac96c; /* Background color on hover */\n    color: #fff; /* Text color on hover */\n}\n\n\n.file-name {\n    margin-top: 10px;\n    display: flex;\n    justify-content: center;\n  }\n  \n  .remove-icon {\n    margin-left: 10px;\n    cursor: pointer;\n    font-size: 18px;\n    color: #333333d4;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header";
import "../css/Userdetails.css";
import { useDispatch, useSelector } from "react-redux";

import CountdownTimer from "./CountdownTimer";
import AOS from "aos";
import "aos/dist/aos.css";
import Select from "react-select";
import { defaultStyles } from "../utils/helpers/SelectCustoms";
import { useCookies } from "react-cookie";
import { getUserData, updateUserData } from "../redux/action/userAction";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";

const dropdownOptions = [
  { label: "Google", value: "Google" },
  { label: "Social Media", value: "Social Media" },
  { label: "HealthProfs", value: "HealthProfs" },
  { label: "Zocdoc", value: "Zocdoc" },
  { label: "Employer", value: "Employer" },
  { label: "Gym or Fitness Studio", value: "Gym or Fitness Studio" },
  { label: "Doctor", value: "Doctor" },
  { label: "Insurance Directory", value: "Insurance" },
  { label: "Past or Current Client", value: "Past or Current Client" },
  { label: "Everlong Employee", value: "Everlong Employee" },
  { label: "Other", value: "Other" },
];

const Referred = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const progressValue = 70;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [referred, setReferred] = useState({});
  const [additionalInfo, setAdditionalInfo] = useState("");

  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);

  const [error, setError] = useState(false);
  const [focusedField, setFocusedField] = useState(null);

  const handleOptionChange = (selectedOption) => {
    setReferred(selectedOption);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        referred: selectedOption,
      })
    );
    setError(false);
  };

  const handleAdditionalInfoChange = (e) => {
    setAdditionalInfo(e.target.value);
    setError(false);
  };

  const handleAdditionalInfoSave = (e) => {
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        referred_info: e.target.value,
      })
    );
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/referred",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  const setUserDataHandler = (uData) => {
    if (uData.referred && uData.referred.value) {
      setReferred(uData.referred);
    } else {
      setReferred({});
    }

    if (uData.referred_info && uData.referred_info) {
      setAdditionalInfo(uData.referred_info);
    } else {
      setAdditionalInfo("");
    }
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const handleSelectButton = () => {
    if (!referred || (referred && !referred.value)) {
      setError("Please select how you heard about us.");
      return;
    }
    if (
      referred &&
      referred.value &&
      (referred?.value === "Employer" ||
        referred?.value === "Gym or Fitness Studio" ||
        referred?.value === "Doctor" ||
        referred?.value === "Past or Current Client" ||
        referred?.value === "Everlong Employee" ||
        referred?.value === "Other") &&
      (!additionalInfo || (additionalInfo && additionalInfo.trim() === ""))
    ) {
      setError("Please share who referred you.");
      return;
    }

    if (
      referred &&
      referred.value &&
      referred.value !== "Doctor" &&
      referred.value !== "Past or Current Client" &&
      referred.value !== "Everlong Employee" &&
      referred.value !== "Other"
    ) {
      setAdditionalInfo("");
      dispatch(
        updateUserData({
          evuid: cookies["evuid"],
          referred_info: "",
        })
      );
    }

    setError(false);
    navigate("/insuranceinformation");
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
      delay: 1400,
    });
  }, []);

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };
  return (
    <>
      <Header progressValue={progressValue} />
      <div className="container page-main1 mt-5" data-aos="fade-up">
        <div className="col-lg-8 col-md-10 col-sm-12 text-left mx-auto mt-5">
          <h3 className="mt-4 text-center">How did you hear about us?</h3>
          <div className="row mt-4">
            <div
              className={`col-md-8 mx-auto mb-3 ${
                error && !referred ? "error" : ""
              }`}
            >
              <div
                className={`col-md-8 mx-auto ${
                  error && !referred ? "error" : ""
                }`}
              >
                <Select
                  id="selectOption"
                  // className={`${referred ? 'selected' : ''}`}
                  value={referred}
                  onChange={handleOptionChange}
                  options={dropdownOptions}
                  placeholder={""}
                  noOptionsMessage={() => "No choices"}
                  isSearchable
                  styles={defaultStyles}
                />

                {referred &&
                  (referred?.value === "Employer" ||
                    referred?.value === "Gym or Fitness Studio" ||
                    referred?.value === "Doctor" ||
                    referred?.value === "Past or Current Client" ||
                    referred?.value === "Everlong Employee" ||
                    referred?.value === "Other") && (
                    <input
                      type="text"
                      id="additionalInfo"
                      className={`form-control mt-4 inputbox ${
                        error && !additionalInfo ? "error" : ""
                      } ${focusedField === "additionalInfo" ? "selected" : ""}`}
                      onChange={handleAdditionalInfoChange}
                      onBlur={handleAdditionalInfoSave}
                      onFocus={() => handleFocus("additionalInfo")}
                      value={additionalInfo}
                      placeholder="Referred by"
                    />
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-8 col-md-10 col-sm-12 mx-auto">
          {error && <div className="text-center text-danger">{error}</div>}
          <div className="mt-3"></div>
          <button
            id="nextButton"
            type="button"
            className={`next-button ${error ? "selected" : ""}`}
            onClick={handleSelectButton}
          >
            Continue
          </button>
          <CountdownTimer />
        </div>
      </div>
    </>
  );
};

export default Referred;

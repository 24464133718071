import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import Profile from "../images/pro.png"; // Import the profile image

import { useCookies } from "react-cookie";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getUserData, updateUserData } from "../redux/action/userAction";
import {
  getDietitian,
  updateQuickNotes,
} from "../redux/action/insurenceProviderAction";
import { DIETITIAN_DATA } from "../utils/constants/insurence-provider";
import moment from "moment";
import { dateFormate } from "../utils/helpers/CommonHelper";
import "../css/AppointmentConfirmation.css";
import Spinner from "./Spinner";

const Appointmentconfirmation = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["evuid"]);
  const dispatch = useDispatch();
  const progressValue = 100;
  const navigate = useNavigate();
  const [focusedField, setFocusedField] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [appointmentData, setAppointmentData] = useState({});
  const [notes, setQuickNotes] = useState("");
  const [error, setError] = useState("");

  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
  const dietitianData = useSelector((state) => state.insurence[DIETITIAN_DATA]);

  useEffect(() => {
    window.addEventListener("popstate", function (event) {
      // Redirect the user to the specific path
      window.location.href = "https://geteverlong.com";
      removeCookie("evuid", {
        expires: new Date(new Date().getTime() - 1000),
      });
      // window.location.href = location;
    });

    return () => {
      window.removeEventListener("popstate", () => console.log("redirected"));
    };
  }, []);

  useEffect(() => {
    // Initialize GTM with your GTM container ID

    // Check if GTM has loaded before pushing data
    if (typeof window.dataLayer !== "undefined") {
      // Push data to the data layer
      window.dataLayer.push({
        event: "pageview", // Replace with the appropriate event name
        pageType: "appointment_confirmation", // Customize as needed
      });
    }
  }, []);

  const setUserDataHandler = (uData) => {
    if (uData && typeof uData.appointment_data != "undefined") {
      setAppointmentData(uData.appointment_data);
    }

    if (uData.quick_notes) {
      setQuickNotes(uData.quick_notes);
    }
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/appointmentconfirmation",
              })
            );
            dispatch(
              getDietitian({
                evuid: cookies["evuid"],
              })
            );
            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      setTimeout(() => {
        window.location.href = "https://geteverlong.com/";
      }, 3000);
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = (e) => {
    setFocusedField(null);

    if (e.target.name) {
      dispatch(
        updateQuickNotes({
          evuid: cookies["evuid"],
          [e.target.name]: e.target.value,
        })
      );
    }
  };

  const handlenoteChange = (e) => {
    setQuickNotes(e.target.value);
    setError("");
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    console.log("file", file);
    const allowedTypes = [
      "image/heic",
      "image/heif",
      "image/jpeg", // Example image types, add more as needed
      "image/jpg", // Example image types, add more as needed
      "image/png",
      "application/pdf",
      "application/msword", // .doc
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    ];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        // File size exceeds 10 MB
        setError(
          "The file size of your referral document is too large. Please compress it or upload a smaller file."
        );
        setFileData(null); // Clear the selected file
      } else if (!allowedTypes.includes(file.type)) {
        setError(
          "File type not accepted. Please upload a PNG, JPG, PDF, DOCX, or HEIC file."
        );
      } else {
        // File size is within the limit
        setError(""); // Clear any previous error message
        setFileData(file);
      }
    }
  };

  const handleRemoveFile = () => {
    // Clear the selected file from the input element
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = null;
    }
    setFileData(null);
  };
  

  const handleSelectButton = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    e.stopPropagation();

    const formBody = new FormData();
    setLoading(true);
    formBody.append("evuid", cookies["evuid"]);
    formBody.append("quick_notes", notes);
    fileData && formBody.append("referral_form", fileData);
    dispatch(
      updateQuickNotes(formBody, (resData) => {
        setLoading(false);
        setSuccessMessage("Success!");
        setTimeout(()=>{
          const buttonElement = document.getElementById('visit-text');
          if (buttonElement) {
            buttonElement.scrollIntoView({ behavior: 'auto' }); // You can use 'auto' instead of 'smooth' for immediate scrolling
            const yOffset = buttonElement.getBoundingClientRect().top;
            window.scrollTo({
              top: yOffset,
              behavior: 'smooth', // You can use 'auto' instead of 'smooth' for immediate scrolling
            });
          }
        },100)
        
        removeCookie("evuid", {
          expires: new Date(new Date().getTime() - 1000),
        });
      })
    );
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      easing: "ease-in-out",
    });
  }, []);

  return (
    <>
      <Header progressValue={progressValue} />

      {!loading && (
        <div
          style={{
            marginTop: "9rem",
          }}
          className="container"
        >
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 mx-auto text-center">
              <h3 className="">Your appointment is confirmed!</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-5 mx-auto text-center">
              <p className="w-60 mb-0">
                You’ll receive an email from our patient portal with a brief
                intake form to complete prior to your appointment as well as a
                link to join.
              </p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-md-8 mx-auto col-sm-12">
              <p className="h4 text-center mt-3">Appointment Details</p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-3">
              {/* Displaying the dietitian's photo and name */}
              <img
                src={dietitianData.avatar_url || Profile}
                className="rounded-circle"
                alt="Dietitian Profile"
                height={180}
                width={180}
              />
              <p className="h4 mt-3 mb-2">
                {dietitianData.first_name} {dietitianData.last_name}, {dietitianData.designation}
              </p>
            </div>

            <div className="col-lg-5 mx-auto  col-md-12 col-sm-12 text-center mt-3">
              {appointmentData && appointmentData.slot && (
                /*<p className="h4 d-flex w-75 mx-auto">{moment(appointmentData.slot.replace(" UTC","").replace(" ","T")+".000Z").format('dddd, MMMM DD, YYYY')} at {moment(appointmentData.slot.replace(" UTC","").replace(" ","T")+".000Z").format('h:mm A')}</p>*/
                <div className="d-flex flex-column w-100">
                  <p className="h4">{dateFormate(appointmentData.slot)} </p>
                  <p className="h4">
                    at{" "}
                    {moment(
                      appointmentData.slot,
                      "YYYY-MM-DD HH:mm:ss Z"
                    ).format("h:mm A")}
                  </p>
                </div>
              )}

              <p className="mb-0">Time shown is in your timezone.</p>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-md-8 col-sm-12 col-10 mt-4 mx-auto">
              <p>
                Please include any other details you’d like to share with your
                registered dietitian. (Optional)
              </p>
              <div className={`col-md-12 mb-3`}>
                <textarea
                  id="note"
                  className={`form-control inputbox ${
                    focusedField === "note" ? "selected" : ""
                  }`}
                  value={notes}
                  name="quick_notes"
                  onChange={handlenoteChange}
                  onFocus={() => handleFocus("note")}
                  onBlur={handleBlur}
                />
              </div>

              <div className="col-12 my-4 text-center">
                <label class={`custom-upload-button mt-2`}>
                  <i class="fas fa-upload upload-icon me-2"></i> Upload Referral
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleUpload}
                    class="file-input d-none"
                  />
                </label>
                {fileData && (
                  <div class="file-name" id="fileNameDisplay">
                    {fileData?.name || "-"}
                    <span className="remove-icon">
                      <i className="fa fa-times" onClick={handleRemoveFile}></i>
                    </span>
                  </div>
                )}
              </div>
              {error && (
                <p
                  className="error-message text-center"
                  style={{ color: "red" }}
                >
                  {error}
                </p>
              )}

              {successMessage && (
                <p className="text-center" style={{ color: "#79ba39" }}>
                  {successMessage}
                </p>
              )}
              <div className="mt-3"></div>
              {(notes || fileData) && ( // Render the button only if the note is valid
                <button
                  id="nextButton"
                  type="button"
                  className="next-button"
                  onClick={handleSelectButton}
                >
                  Submit
                </button>
              )}
            </div>
          </div>

          <div className="row mt-1" id="visit-text">
            <div className="col-lg-6 col-md-8 col-sm-12 text-center col-10 mt-2 mb-3   mx-auto">
              To make any changes to your appointment, please visit our patient
              portal.
            </div>
          </div>
        </div>
      )}
      {loading && (
        <div className="container page-main1 " data-aos="fade-in">
          <div className="row">
            <Spinner text="Please wait" />
          </div>
        </div>
      )}
    </>
  );
};

export default Appointmentconfirmation;

// redux/reducers/countdownSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  timeRemaining: 15 * 60,
};

const countdownSlice = createSlice({
  name: "countdown",
  initialState,
  reducers: {
    setTimer(state, action) {
      const timer = localStorage.getItem("timer");
      if (timer) {
        state.timeRemaining = timer;
      }
    },
    updateTimeRemaining(state, action) {
      state.timeRemaining = action.payload;
      localStorage.setItem("timer", action.payload);
    },
    clearAllState: () => initialState,
  },
});

export const { updateTimeRemaining, setTimer, clearAllState } = countdownSlice.actions;
export default countdownSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const SelectStatesSlice = createSlice({
  name: 'SelectStates',
  initialState: null,
  reducers: {
    setSelectStates: (state, action) => action.payload,
    clearSelectStatesState: () => null,

  },
});

export const { setSelectStates ,clearSelectStatesState} = SelectStatesSlice.actions;

export default SelectStatesSlice.reducer;


import { DIETITIAN_DATA, INSURENCE_PROVIDER_LIST, ORGANIZATION_MEMBERS_LIST, SLOT_LIST } from "../../utils/constants/insurence-provider";

const INIT_STATE = {
    INSURENCE_PROVIDER_LIST:[],
    ORGANIZATION_MEMBERS_LIST:{},
    SLOT_LIST:[],
    DIETITIAN_DATA:{}
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case INSURENCE_PROVIDER_LIST: {
            return {
                ...state,
                INSURENCE_PROVIDER_LIST: action.payload,
            }
        }

        case ORGANIZATION_MEMBERS_LIST: {
            return {
                ...state,
                ORGANIZATION_MEMBERS_LIST: action.payload,
            }
        }

        case SLOT_LIST: {
            return {
                ...state,
                SLOT_LIST: action.payload,
            }
        }

        case DIETITIAN_DATA: {
            return {
                ...state,
                DIETITIAN_DATA: action.payload,
            }
        }

        

        
        
        default: {
            return state;
        }
    }
};

export default reducerFunc;

import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  createContext,
} from "react";
import CVCInput from "./CVCInput";
import DateInput from "./DateInput";
import CardNumberInput from "./CardNumberInput";
import { USER_DATA } from "../../utils/constants/user";
import { useSelector } from "react-redux";

export const CreditCardDataContext = createContext(null);

export const CardProvider = ({ children }) => {
  const [cardData, setCardData] = useState(undefined);

  return (
    <CreditCardDataContext.Provider value={{ cardData, setCardData }}>
      {children}
    </CreditCardDataContext.Provider>
  );
};

const CreditCardInput = (props) => {
  const [focusIndex, setFocusIndex] = useState([]);
  const userData = useSelector((state) => state.user[USER_DATA]);

  const setUserDataHandler = (uData) => {
    if (uData.payment_method_data) {
    } else {
    }
  };

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const setCardErrorText = (name, text) =>
    props.setCardError({ ...props.cardError, [name]: text });

  const leaveField = (index) => {
    const indexArray = new Array(3).fill(false);
    indexArray[index] = false;
    setFocusIndex(indexArray);
  };

  const setFocusOnFirst = useCallback(() => {
    leaveField(0);
  }, []);

  useEffect(() => {
    leaveField(0);
  }, []);

  useEffect(() => {
    window.addEventListener("focus", setFocusOnFirst);
    return () => {
      window.removeEventListener("focus", setFocusOnFirst);
    };
  }, [setFocusOnFirst]);

  return (
    <CreditCardDataContext.Provider
      value={{ cardData: props.cardData, setCardData: props.setCardData }}
    >
      <Fragment>
        <div className="input-group">
          <CardNumberInput
            cardError={props.cardError?.number}
            leaveFieldCallback={leaveField}
            focus={focusIndex[0]}
            tabIndex={0}
            setCardError={setCardErrorText}
          />
          <DateInput
            cardError={props.cardError?.expire}
            leaveFieldCallback={leaveField}
            focus={focusIndex[1]}
            tabIndex={1}
            setCardError={setCardErrorText}
          />
          <CVCInput
            cardError={props.cardError?.cvv}
            leaveFieldCallback={leaveField}
            focus={focusIndex[2]}
            tabIndex={2}
            setCardError={setCardErrorText}
          />
        </div>
        <div>
          {Object.keys(props.cardError).map((key) => (
            <div className="text-danger">{props.cardError[key]}</div>
          ))}
        </div>
      </Fragment>
    </CreditCardDataContext.Provider>
  );
};

export default CreditCardInput;

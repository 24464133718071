// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* In your CSS file */
.rounded-icon {
    display: inline-block;
    padding: 5px; /* Adjust this padding to control the roundness */
    border-radius: 50%; /* Make the element round */
    transition: background-color 0.2s ease-in-out; /* Optional: Add a smooth transition */
}


.rounded-icon-image {
    width: 30px; /* Set the width of the image */
    height: 30px; /* Set the height of the image */
}
`, "",{"version":3,"sources":["webpack://./src/css/heder.css"],"names":[],"mappings":"AAAA,qBAAqB;AACrB;IACI,qBAAqB;IACrB,YAAY,EAAE,iDAAiD;IAC/D,kBAAkB,EAAE,2BAA2B;IAC/C,6CAA6C,EAAE,sCAAsC;AACzF;;;AAGA;IACI,WAAW,EAAE,+BAA+B;IAC5C,YAAY,EAAE,gCAAgC;AAClD","sourcesContent":["/* In your CSS file */\n.rounded-icon {\n    display: inline-block;\n    padding: 5px; /* Adjust this padding to control the roundness */\n    border-radius: 50%; /* Make the element round */\n    transition: background-color 0.2s ease-in-out; /* Optional: Add a smooth transition */\n}\n\n\n.rounded-icon-image {\n    width: 30px; /* Set the width of the image */\n    height: 30px; /* Set the height of the image */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

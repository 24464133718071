import { USER_DATA, FIRST_LOAD } from "../../utils/constants/user";


const INIT_STATE = {
    USER_DATA:{},
    FIRST_LOAD:true
};

const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case USER_DATA: {
            return {
                ...state,
                USER_DATA: action.payload,
            }
        }

        case FIRST_LOAD: {
            return {
                ...state,
                FIRST_LOAD: action.payload,
            }
        }
        
        default: {
            return state;
        }
    }
};

export default reducerFunc;

import { axiosGet } from "../../services/apiURL";
import { HEALTH_CONCERN_LIST } from "../../utils/constants/health-concern";

export const getHealthConcernList = (cb) => {
    return dispatch => {
        return axiosGet(
			{ url: 'master/list-concerns', reqBody: {} },
			(response) => {
                if(typeof cb == "function") cb(response);
                if(response.data.success){
                    dispatch({ type: HEALTH_CONCERN_LIST, payload: response.data.result });
                }
			},
		);
    }
};
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useCookies } from "react-cookie";
import { getUserData, updateUserData } from "../redux/action/userAction";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getDietitian } from "../redux/action/insurenceProviderAction";
import { DIETITIAN_DATA } from "../utils/constants/insurence-provider";

import Header from "./header";
import "../css/welcome.css";
import "aos/dist/aos.css";
import Profile from "../images/pro.png"; // Import the profile image
import moment from "moment";
import CountdownTimer from "./CountdownTimer";
import { dateFormate } from "../utils/helpers/CommonHelper";
import Spinner from "./Spinner";

const DietitianScreen = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const dispatch = useDispatch();
  const progressValue = 65;
  const navigate = useNavigate();

  const userData = useSelector((state) => state.user[USER_DATA]);
  const dietitianData = useSelector((state) => state.insurence[DIETITIAN_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);

  const getUser = () => {
    // dispatch(clearDietitianData())
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/dietitian",
              })
            );
            dispatch(
              getDietitian({
                evuid: cookies["evuid"],
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
            //window.scrollTo(0, 0);
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  const handleContinueButton = () => {
    navigate("/referred");
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 900);
  }, []);

  return (
    <>
      <Header progressValue={progressValue} />
      {dietitianData && dietitianData.id && (
        <div
          className="container page-main1 diet-main-container h-auto"
          // data-aos-delay="100"
          // data-aos="zoom-in"
        >
          <div className="row mt-4">
            <div className="col-12 mt-5  mx-auto text-center">
              <h3>Selected Appointment</h3>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-3">
              {/* Displaying the dietitian's photo and name */}
              <img
                src={dietitianData.avatar_url || Profile}
                alt="Dietitian Profile"
                height={180}
                width={180}
                className="rounded-circle"
              />
              <p className="h4 mt-3 mb-3">
                {dietitianData.first_name} {dietitianData.last_name},{" "}
                {dietitianData.designation || ""}
              </p>
            </div>

            <div className="col-lg-12 mx-auto col-md-12 col-sm-12 text-center mt-3">
              {userData &&
              userData.appointment_data &&
              userData.appointment_data.slot ? (
                <div>
                  <p className="h4">
                    {dateFormate(userData.appointment_data.slot)}
                  </p>
                  <p className="h4">
                    at{" "}
                    {moment(
                      userData.appointment_data.slot,
                      "YYYY-MM-DD HH:mm:ss Z"
                    ).format("h:mm A")}
                  </p>
                </div>
              ) : (
                ""
              )}
              <p className="mb-0">Time shown is in your timezone.</p>
            </div>
          </div>

          <div className="mt-3">
            <button
              id="nextButton"
              type="button"
              className="next-button"
              onClick={handleContinueButton} // Navigate back to the referred page
            >
              Continue
            </button>
            <CountdownTimer />
          </div>
        </div>
      )}
      {!dietitianData.id && (
        <div className="container page-main1 ">
          <div className="row">
            <Spinner text="Please wait" />
          </div>
        </div>
      )}
    </>
  );
};

export default DietitianScreen;

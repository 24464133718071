import { createSlice } from "@reduxjs/toolkit";

const appointmentBookingSlice = createSlice({
  name: "AppointmentBooking",
  initialState: {
    value: null,
    selectedSlots: [],
    selectedDate: null, // Add selectedDate field
  },
  reducers: {
    OnValueChange: (state, action) => {
      state.value = action.payload;
    },
    setSelectedSlots: (state, action) => {
      state.selectedSlots = action.payload;
    },
    clearAppointmentBookingState: (state) => {
      state.value = Date.now();
      state.selectedSlots = [];
      state.selectedDate = null;
    },
  },
});

export const {
  OnValueChange,
  setSelectedSlots,
  setSelectedDate, // Export the new reducer
  clearAppointmentBookingState,
} = appointmentBookingSlice.actions;

export default appointmentBookingSlice.reducer;

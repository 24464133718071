import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from "./header";
import "../css/Userdetails.css";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { getUserData, updateUserData } from '../redux/action/userAction';
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { isValidEmail, isValidPhone } from '../utils/helpers/CommonHelper';

const Userdetails = () => {
  const [cookies, setCookie] = useCookies(['evuid']);
  const progressValue = 50;
  const navigate = useNavigate();
  const [focusedField, setFocusedField] = useState(null);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [selectedInsurance, setInsurance] = useState({});

  const getUser = () => {
    dispatch(getUserData({
      evuid: cookies["evuid"]
    }, (resData) => {
      if (resData.data.success && resData.data.result && resData.data.result.evuid) {
        dispatch(updateUserData({
          evuid: cookies["evuid"],
          current_step_id: "/userdetails"
        }));

        if (firstLoad && resData.data.result.current_step_id) {
          navigate(resData.data.result.current_step_id)
        }
        dispatch({ type: FIRST_LOAD, payload: false });
      } else {
        navigate("/")
      }
    }));
  }

  const setUserDataHandler = (uData) => {
    if(uData.insurence && uData.insurence.insurance_provider_id) {
      setInsurance({
        value:uData.insurence.insurance_provider_id,
        label:uData.insurence.insurance_provider_name
      });
    } else {
      setInsurance({});
    }
    
    if(uData.first_name) {
      setFirstName(uData.first_name);
    } else {
      setFirstName("");
    }

    if(uData.last_name) {
      setLastName(uData.last_name);
    } else {
      setLastName("");
    }

    if(uData.mobile) {
      setPhone(uData.mobile);
    } else {
      setPhone("");
    }

    if(uData.email) {
      setEmail(uData.email);
    } else {
      setEmail("");
    }


  }

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/")
    } else {
      getUser();
      
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData])


  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setError(false);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setError(false);
  };
  const phoneRef = useRef(null);

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setError(false);
  };

  const onInputPhoneChange = (e) => {
    let phoneNumber = e.target.value;
    const regex = /^\d{10}$/;
    if(regex.test(phoneNumber) && phoneNumber?.length >= 10){
      phoneNumber= `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
      return setPhone(phoneNumber)
    }
    setError(false)
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(false);
  };

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName);
  };

  const handleBlur = (e) => {
    if(['first_name', 'last_name', 'email', 'mobile'].includes(e.target.name)){
      dispatch(updateUserData({
        evuid: cookies["evuid"],
        [e.target.name]: e.target.value
      }));
    }
    setFocusedField(null);
    setError(false);
  };


  const handleSelectButton = () => {

    if (!firstName.trim()) {
      setError("Please complete all required fields.");
      return;
    }

    if (!email.trim()) {
      setError("Please complete all required fields.");
      return;
    }
    if (!lastName.trim()) {
      setError("Please complete all required fields.");
      return;
    }
    if (!isValidPhone(phone)) {
      setError("Please complete all required fields.");
      return;
    }
    
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }

    dispatch(updateUserData({
      evuid: cookies["evuid"],
      first_name:firstName,
      last_name:lastName,
      email:email,
      mobile:phone
    }));
    setError(false);
    navigate('/appointmentbooking');
  };


  useEffect(() => {
    AOS.init({
      duration: 900,
      easing: 'ease-in-out',
    });
  }, []);

  const backRoute = selectedInsurance && (selectedInsurance.label === "Private Pay" || selectedInsurance.label === "HSA / FSA")
  ? '/dateofbirth'
  : '/youreeligible';
  return (
    <>
    <Header progressValue={progressValue} backRoute={backRoute} />


   
      <div className="container page-main1 h-auto" data-aos="fade-up">
        <div className="row mt-4">
          <div className='col-lg-7 col-md-8 col-12 text-left mx-auto mt-5' >
            <h3 className='text-center'>Let’s get you booked!</h3>
            <p className='mt-4 text-center ' >Your personal registered dietitian is just one click away.</p>
     
            <div className="row mt-5">
              <div className={`col-md-6 mb-3 ${error && !firstName ? 'error' : ''}`}>
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="first_name"
                  className={`form-control inputbox ${focusedField === 'firstName' ? 'selected' : ''}`}
                  value={firstName}
                  onInput={handleFirstNameChange}
                  onFocus={() => handleFocus('firstName')}
                  onBlur={handleBlur}
                  // autoComplete="off"
                />
              </div>
              <div className={`col-md-6 mb-3 ${error && !lastName ? 'error' : ''}`}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="last_name"
                  className={`form-control inputbox ${focusedField === 'lastName' ? 'selected' : ''}`}
                  value={lastName}
                  onInput={handleLastNameChange}
                  onFocus={() => handleFocus('lastName')}
                  onBlur={handleBlur}
                  // autoComplete="off"
                />
              </div>
            </div>
            <div className="row">

              <div className={`col-md-6 mb-3 ${error && (!isValidPhone(phone)) ? 'error' : ''}`}>
                <label htmlFor="phone">Phone Number</label>
                <InputMask
                  mask="(999) 999-9999"
                  value={phone}
                  name="mobile"
                  onInput={onInputPhoneChange}
                  onChange={handlePhoneChange}
                  onFocus={() => handleFocus("phone")}
                  onBlur={handleBlur}
                  className={`form-control inputbox phone ${focusedField === "phone" ? "selected" : ""}`}
                  inputRef={phoneRef}
                />
              </div>
              <div className={`col-md-6 mb-3 ${error && !isValidEmail(error) ? 'error' : ''}`}>
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`form-control inputbox ${focusedField === 'email' ? 'selected' : ''}`}
                  value={email}
                  onInput={handleEmailChange}
                  onFocus={() => handleFocus('email')}
                  onBlur={handleBlur}
                  // autoComplete="off"
                />
              </div>
            </div>
            <p className=''>By clicking continue, you acknowledge that you have read, understood, and accepted the <a style={{color:'#79ba39'}} href='https://geteverlong.com/consent-policy/' target='_blank'>Everlong Consent</a> and <a style={{color:'#79ba39'}} href='https://geteverlong.com/communication-policy/' target='_blank'>Everlong Communication</a> policies.</p>

          </div>
        </div>
        <div className="col-lg-5 col-md-8 col-sm-12 col-10 mx-auto">
          {error && <div className="text-danger text-center">{error}</div>}
          <div className="mt-5">
            <button
              id="nextButton"
              type="button"
              className={`next-button ${error ? 'selected' : ''}`}
              onClick={handleSelectButton}
            >
              Continue to booking
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userdetails;

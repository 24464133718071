import React, { Fragment, useRef, useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import PaymentIcon from 'react-payment-icons';
import InputMask from 'react-input-mask';

import { number } from "card-validator";
import { CreditCardDataContext } from './CredtCardInput';
import { USER_DATA } from "../../utils/constants/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";


//TODO: still not proper valid at last char if number is not valid

const CardNumberInput = ({leaveFieldCallback, focus, tabIndex, setCardError,cardError}) => {

  const [cardType, setCardType] = useState("");
  const [error, setError] = useState(false);
  const [info, setInfo] = useState("");
  const [cardNumberData, setCardNumber] = useState("");
  const inputRef = useRef(null);
  const userData = useSelector((state) => state.user[USER_DATA]);

  const CardContext = useContext(CreditCardDataContext);
  const absLength = (value) => value.split("").map((item) => parseInt(item))
  .filter((item) => !isNaN(item))
  .length;


  const handleChange = (event) => {
    const cardNumberValue = event?.target?.value;
    const cardNumberValidator = number(cardNumberValue);

    setCardNumber(cardNumberValue);
    setCardType(cardNumberValidator?.card?.type || "");
    if(absLength(cardNumberValue) > 0  && !cardNumberValidator.isPotentiallyValid) {
      setError(true);
      setInfo("are you sure is valid?");
      setCardError("number", "Please enter a valid credit card number.")
    } else if(absLength(cardNumberValue) > 0  && cardNumberValidator.isPotentiallyValid) {
      setError(false);
      setInfo("");
      setCardError("number", "");
    } else if (!/([0-9]+)/.test(cardNumberValue)) {
      setError(false);
      setInfo("");
      setCardError("number", "");
    } else if (cardNumberValidator.isValid) {
      setError(false);
      setInfo("");
      setCardError("number", "");
      if(leaveFieldCallback) {
        leaveFieldCallback(tabIndex + 1);
      }
      if (cardNumberValidator.card) {
        const cvcLength = cardNumberValidator.card.code.size;
        CardContext?.setCardData({
          ...CardContext.cardData,
          cardNumber: event?.target?.value || "",
          cvclenght: cvcLength
        });
      }
    }
    if (!cardNumberValidator.isValid) {
      CardContext?.setCardData({
        ...CardContext.cardData,
        cardNumber: "",
        cvclenght: 0
      });
    }
  }

  const handleBlur = (event) => {
    const cardNumberValue = event?.target?.value;
    const cardNumberValidator = number(cardNumberValue);
    if(cardNumberValidator.isValid) {
      setError(false);
      setInfo("");
      setCardError("number", "");
      if (cardNumberValidator.card) {
        const cvcLength = cardNumberValidator.card.code.size;
        CardContext?.setCardData({
          ...CardContext.cardData,
          cardNumber: event?.target?.value || "",
          cvclenght: cvcLength
        });
      }
    } else {
      setError(true);
      setInfo("still something sticky");
      setCardError("number", "Please enter a valid credit card number.")
      if(leaveFieldCallback) {
        leaveFieldCallback(tabIndex);
      }
      CardContext?.setCardData({
        ...CardContext.cardData,
        cardNumber: "",
        cvclenght: 0
      });
    }
  }


  useEffect(() => {
    if(focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  useEffect(()=>{
    if(userData && userData.payment_method_data && userData.payment_method_data.cardNumber){
      setCardNumber(userData.payment_method_data.cardNumber);
    }
  }, [userData])


  return (
    <Fragment>
      <InputMask
        mask="9999 9999 9999 9999"
        maskChar=""
        alwaysShowMask={true}
        // onChange={handleChange}
        onBlur={handleBlur}
        value={cardNumberData}
      >
      {() =>      
      <input 
          type="text"
          autoFocus={focus}
          inputMode="numeric" // Set input mode to "numeric" to open the numeric keyboard
          className={`form-control card-number inputbox ${(error||cardError) ? 'error' :''}`}
          label="Card Number"
          placeholder="Card Number"
          helperText={info} 
          error={error}
          tabIndex={tabIndex}
          required
          onInput={handleChange}
          ref={inputRef} 
          name={`card_number_${Math.random()}`}
          // value={CardContext?.cardData?.cardNumber}
      />
      }
      </InputMask>
      <div className="payment-icon-wrapper">
        {cardType === "" && <FontAwesomeIcon icon={faCreditCard} />}
        {cardType !== "" && (
          <PaymentIcon
            id={cardType}
            style={{ margin: 10, width: 24 }}
            className="payment-icon"
          />
        )}
      </div>
    </Fragment>
  )
}

export default CardNumberInput;
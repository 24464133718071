import React, { useState, useRef, useEffect, useContext } from "react";
// import { TextField } from '@material-ui/core';
import InputMask from "react-input-mask";
import {
  expirationDate,
} from "card-validator";
import { CreditCardDataContext } from "./CredtCardInput";
import { USER_DATA } from "../../utils/constants/user";
import { useSelector } from "react-redux";

const DateInput = ({
  leaveFieldCallback,
  focus,
  tabIndex,
  setCardError,
  cardError,
}) => {
  const [error, setError] = useState(false);
  const [info, setInfo] = useState("");
  const [cardDate, setCardDate] = useState("");
  const inputRef = useRef(null);

  const userData = useSelector((state) => state.user[USER_DATA]);

  const CardContext = useContext(CreditCardDataContext);
  const handleChange = (event) => {
    let value = event?.target?.value;
    const expiryDate = expirationDate(value);
    setCardDate(value);
    if (expiryDate.isValid) {
      if (leaveFieldCallback) {
        leaveFieldCallback(tabIndex + 1);
      }
      setError(false);
      setInfo("");
      setCardError("expire", "");
      CardContext?.setCardData({
        ...CardContext.cardData,
        date: {
          month: expiryDate.month,
          year: expiryDate.year,
        },
      });
    } else {
      CardContext?.setCardData({
        ...CardContext.cardData,
        date: {
          month: null,
          year: null,
        },
      });
    }
  };

  const handleBlur = (event) => {
    const value = event?.target?.value;
    const expiryDate = expirationDate(value);

    if (expiryDate.isValid) {
      setError(false);
      setInfo("");
      setCardError("expire", "");
      CardContext?.setCardData({
        ...CardContext.cardData,
        date: {
          month: expiryDate.month,
          year: expiryDate.year,
        },
      });
    } else {
      setError(true);
      setCardError("expire", "Please enter a valid expiration date.");
      CardContext?.setCardData({
        ...CardContext.cardData,
        date: {
          month: null,
          year: null,
        },
      });
    }
  };

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (
      userData &&
      userData.payment_method_data &&
      userData.payment_method_data.expire_month
    ) {
      setCardDate(
        userData.payment_method_data?.expire_month +
          " / " +
          userData.payment_method_data?.expire_year
      );
    }
  }, [userData]);

  return (
    <InputMask
      mask="99 / 99"
      maskChar=" "
      onBlur={handleBlur}
      tabIndex={tabIndex}
      value={cardDate}
    >
      {() => (
        <input
          name={`expire_${Math.random()}`}
          placeholder="DD / MM"
          inputMode="numeric" // Set input mode to "numeric" to open the numeric keyboard
          type="text"
          className={`form-control inputbox ${
            error || cardError ? "error" : ""
          }`}
          error={error}
          helperText={info}
          onInput={handleChange}
          autoFocus={focus}
          required
          ref={inputRef}
        />
      )}
    </InputMask>
  );
};

export default DateInput;

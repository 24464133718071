import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Header from "./header";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears } from "date-fns";
import { useCookies } from "react-cookie";
import { FIRST_LOAD, USER_DATA } from "../utils/constants/user";
import { getUserData, updateUserData } from "../redux/action/userAction";
import moment from "moment";

const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 1923 }, (_, i) => {
  const year = currentYear - i;
  return { value: year, label: year.toString() };
});

const DateofBirth = () => {
  const [cookies, setCookie] = useCookies(["evuid"]);
  const progressValue = 30;
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [selectedDay, setSelectedDay] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(false);
  const [selectedYear, setSelectedYear] = useState(false);
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [isDaySelected, setIsDaySelected] = useState(false);
  const [isYearSelected, setIsYearSelected] = useState(false);
  const [selectedInsurance, setInsurance] = useState({});
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user[USER_DATA]);
  const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);

  const selectedDateOfBirth = useSelector((state) => state.dateOfBirth);

  const setUserDataHandler = (uData) => {
    if (uData.date_of_birth) {
      const dateOfBirthUTC = moment.utc(uData.date_of_birth);

      let dd = moment(dateOfBirthUTC).format("DD");
      let mm = months.find(
        (mm) => mm.value === moment(dateOfBirthUTC).format("MM")
      );
      let YYYY = moment(dateOfBirthUTC).format("YYYY");
      setSelectedDay({ value: dd, label: dd });
      setSelectedMonth(mm);
      setSelectedYear({ value: YYYY, label: YYYY });
    }

    if (uData.insurence && uData.insurence.insurance_provider_id) {
      setInsurance({
        value: uData.insurence.insurance_provider_id,
        label: uData.insurence.insurance_provider_name,
      });
    } else {
      setInsurance({});
    }
  };

  const getUser = () => {
    dispatch(
      getUserData(
        {
          evuid: cookies["evuid"],
        },
        (resData) => {
          if (
            resData.data.success &&
            resData.data.result &&
            resData.data.result.evuid
          ) {
            dispatch(
              updateUserData({
                evuid: cookies["evuid"],
                current_step_id: "/dateofbirth",
              })
            );

            if (firstLoad && resData.data.result.current_step_id) {
              navigate(resData.data.result.current_step_id);
            }
            dispatch({ type: FIRST_LOAD, payload: false });
          } else {
            navigate("/");
          }
        }
      )
    );
  };

  useEffect(() => {
    if (!cookies["evuid"]) {
      navigate("/");
    } else {
      getUser();
    }
  }, [cookies["evuid"]]);

  useEffect(() => {
    setUserDataHandler(userData);
  }, [userData]);

  const calculateAge = () => {
    if (selectedYear && selectedMonth && selectedDay) {
      const selectedDate = new Date(
        selectedYear.value,
        selectedMonth.value - 1,
        selectedDay.value
      );
      const age = differenceInYears(new Date(), selectedDate);
      return age;
    }
    return -1; // Return a negative value if the date is not fully selected
  };

  const handleSelectButton = () => {
    const age = calculateAge();

    if (selectedYear && selectedMonth && selectedDay) {
      if (age < 15) {
        setError(
          "We currently do not provide services to individuals under 15 years of age."
        );
      } else {
        setError(false);
        navigate("/youreeligible");
      }
    } else {
      setError("Please select your date of birth.");
    }

    if (
      selectedInsurance &&
      (selectedInsurance.label === "Private Pay" ||
        selectedInsurance.label === "HSA / FSA") &&
      age >= 15 &&
      age <= 18
    ) {
      navigate("/userdetails2");
    } else if (
      selectedInsurance &&
      (selectedInsurance.label === "Private Pay" ||
        selectedInsurance.label === "HSA / FSA") &&
      age > 17
    ) {
      navigate("/userdetails");
    } else {
      navigate("/youreeligible");
    }
  };

  const handleDayChange = (selectedOption) => {
    setSelectedDay(selectedOption);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        date_of_birth: `${selectedYear.value}-${String(
          selectedMonth?.value
        )}-${String(selectedOption?.value)?.padStart(2, "0")}`,
      })
    );
    setIsDaySelected(true);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedDay(null); // Reset the day selection when the month changes
    setSelectedMonth(selectedOption);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        date_of_birth: `${selectedYear.value}-${String(
          selectedOption?.value
        )?.padStart(2, "0")}-${String(selectedDay?.value)?.padStart(2, "0")}`,
      })
    );
    setIsMonthSelected(true);
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    setIsYearSelected(true);
    dispatch(
      updateUserData({
        evuid: cookies["evuid"],
        date_of_birth: `${selectedOption.value}-${String(
          selectedMonth?.value
        )?.padStart(2, "0")}-${String(selectedDay?.value)?.padStart(2, "0")}`,
      })
    );
  };

  const getDaysOptions = () => {
    if (!selectedMonth) return [];
    const monthValue = selectedMonth.value;
    const daysInMonth = new Date(
      selectedYear?.value || currentYear,
      monthValue,
      0
    ).getDate();
    let days = [];
    for (let i = 1; i <= daysInMonth; i++) {
      days.push({
        value: String(i).padStart(2, "0"),
        label: String(i).padStart(2, "0"),
      });
    }

    return days;
  };
  useEffect(() => {
    AOS.init({
      duration: 800, //
      easing: "ease-in-out",
    });
    if (selectedDateOfBirth.month) {
      setSelectedMonth(selectedDateOfBirth.month);
      setIsMonthSelected(true);
    }
    if (selectedDateOfBirth.year) {
      setSelectedYear(selectedDateOfBirth.year);
      setIsYearSelected(true);
    }
    if (selectedDateOfBirth.day) {
      setSelectedDay(selectedDateOfBirth.day);
      setIsDaySelected(true);
    }
  }, []);

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? "#79ba39" : "#79ba39",
      height: "50px",
      boxShadow: state.isFocused ? "0 0 0 1px #79ba39" : null,
      "&:hover": {
        borderColor: "#79ba39",
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      height: "50px",
      backgroundColor: state.isFocused ? "#79ba39" : null,
      "&:hover": {
        borderColor: "#79ba39",
      },
      color: state.isFocused ? "white" : "black",
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: "black",
    }),
  };

  return (
    <>
      <Header progressValue={progressValue} backRoute={"/insuranceselection"} />

      <div className="container page-main1 mt-0">
        <div
          className="col-lg-5 col-md-8 col-sm-12  text-center mx-auto mt-5"
          data-aos="fade-up"
        >
          <h3 className="mt-4 mb-0">What’s your date of birth?</h3>
        </div>
        <div
          className="col-lg-5 col-md-8 col-sm-12 mt-4 col-12 mx-auto"
          data-aos="fade-up"
        >
          <div className="d-flex justify-content-center">
            <div className="mr-2" style={{ marginRight: "10px" }}>
              <Select
                options={months}
                value={selectedMonth}
                onChange={handleMonthChange}
                placeholder="Month"
                styles={customStyles}
                defaultValue={selectedDateOfBirth.month || ""}
              />
              {error && !isMonthSelected && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select a month.
                </p>
              )}
            </div>
            <div className="mr-2" style={{ marginRight: "10px" }}>
              <Select
                options={getDaysOptions()}
                defaultValue={selectedDateOfBirth.day || ""}
                value={selectedDay}
                onChange={handleDayChange}
                placeholder="Day"
                styles={customStyles}
              />
              {error && !isDaySelected && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select a day.
                </p>
              )}
            </div>
            <div className="mr-2" style={{ marginRight: "10px" }}>
              <Select
                options={years}
                value={selectedYear}
                defaultValue={selectedDateOfBirth.year || ""}
                onChange={handleYearChange}
                placeholder="Year"
                styles={customStyles}
              />
              {error && !isYearSelected && (
                <p className="error-message" style={{ color: "red" }}>
                  Please select a year.
                </p>
              )}
            </div>
          </div>
          <div className="mt-5">
            {error && (
              <p className="error-message" style={{ color: "red" }}>
                {error}
              </p>
            )}
            {selectedYear &&
              selectedMonth &&
              selectedDay &&
              calculateAge() < 15 && (
                <p
                  className="error-message text-center"
                  style={{ color: "red" }}
                >
                  We currently do not provide services to individuals under 15
                  years of age.
                </p>
              )}
            {selectedYear &&
              selectedMonth &&
              selectedDay &&
              calculateAge() >= 15 && (
                <button
                  id="nextButton"
                  type="button"
                  className="next-button"
                  onClick={handleSelectButton}
                  data-aos="fade-up"
                >
                  Check Eligibility
                </button>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DateofBirth;

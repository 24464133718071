import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './header';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/welcome.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, createUserData, updateUserData } from '../redux/action/userAction';
import { useCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import {
    setOtherInput,
    setShowErrorMessage,
} from '../redux/Slice/welcomeSlice';
import { getHealthConcernList } from '../redux/action/healthConcernsAction';
import { HEALTH_CONCERN_LIST } from '../utils/constants/health-concern';
import { USER_DATA, FIRST_LOAD } from '../utils/constants/user';
const EverlongForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['evuid']);
    const userData = useSelector((state) => state.user[USER_DATA]);
    const firstLoad = useSelector((state) => state.user[FIRST_LOAD]);
    const healthConcersList = useSelector((state) => state.healthConcerns[HEALTH_CONCERN_LIST] || []);

    const progressValue = 0;
    const otherInput = useSelector((state) => state.welcome.otherInput);
    
    const [error, setError] = useState(false);
    
    const [selectedHealthConcern, setSelectedOption] = useState({});
    const [selectedSubOption, setSelectedSubOption] = useState({});

    const [otherInputValue, setOtherInputValue] = useState("");
    const [focusedField, setFocusedField] = useState(null);



    const createUser = () => {
        const evuid = uuidv4();
        setCookie('evuid', evuid, {
            path: '/',
            expires: new Date(new Date().getTime() + ((24 * 60 * 60 * 1000) * 10))
        })
    }

    const getUser = () => {
        dispatch(getUserData({
            evuid: cookies["evuid"]
        }, (resData) => {
            if (!resData.data.success) {
                dispatch(createUserData({
                    evuid: cookies["evuid"],
                    current_step_id: "/"
                }));
                dispatch({ type: FIRST_LOAD, payload: false });
            } else if (resData.data.success && resData.data.result && resData.data.result.evuid) {
                if (firstLoad && resData.data.result.current_step_id) {
                    navigate(resData.data.result.current_step_id)
                }
                dispatch({ type: FIRST_LOAD, payload: false });
            }
        }));
    }



    useEffect(() => {

        if (!healthConcersList || (Array.isArray(healthConcersList) && healthConcersList.length === 0)) {
            getHealthList();
        }
        if (!cookies["evuid"]) {
            createUser();
        } else {
            getUser();
        }
    }, [cookies["evuid"]]);

    useEffect(() => {
        setUserDataHandler(userData);
    }, [userData, healthConcersList]);


    const handleSelectButton = () => {
        if(!selectedHealthConcern.value) {
            setError("Please select a visit reason.");
            return;
        }
        if (!selectedHealthConcern || (selectedHealthConcern.label === 'Other' && !otherInput)) {
            setError("Please select an option or provide additional information.");
            return;
        }

        if (selectedHealthConcern && selectedHealthConcern.label !== "Other" && Array.isArray(selectedHealthConcern.subOptions) && selectedHealthConcern.subOptions.length > 0 && (!selectedSubOption || (selectedSubOption && !selectedSubOption.id))) {
            setError("Please select a specific option.");
            return;
        }

        if (!selectedHealthConcern.subOptions) {
            setSelectedSubOption(null);
        }

        setError(false);
        navigate('/selectstates');
    };

    const getHealthList = () => {
        dispatch(getHealthConcernList());
    }

    const setUserDataHandler = (uData) => {
        if (uData && uData.specialty && uData.specialty.id) {
            let findOption = healthConcersList.find((op) => op.id === uData.specialty.id);
            if (findOption && findOption._id) {
                setSelectedOption(findOption);

                if (uData.specialty && uData.specialty.otherText) {
                    setOtherInputValue(uData.specialty.otherText);
                    dispatch(setOtherInput(uData.specialty.otherText));
                }

                if (uData.specialty && uData.specialty.subOptions && uData.specialty.subOptions.id) {
                    setSelectedSubOption(uData.specialty.subOptions);
                }
            }
        }
    }



    useEffect(() => {

        AOS.init({
            duration: 900,
            easing: 'ease-in-out',
        });
    }, []);



    const handleAdditionalInfoChange = (e) => {
        const inputValue = e.target.value;

        setOtherInputValue(e.target.value)
        dispatch(setOtherInput(inputValue));
        setError(false);
    };

    const handleAdditionalInfoSave = (e) => {
        const inputValue = e.target.value;
        dispatch(setOtherInput(inputValue));
        dispatch(updateUserData({
            specialty: {
                id: selectedHealthConcern.id,
                label: selectedHealthConcern.label,
                value: selectedHealthConcern.value,
                otherText: inputValue
            },
            evuid: cookies["evuid"]
        }))
        setError(false);
    };


    const handleHealthConcernChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setSelectedSubOption({});
        dispatch(setOtherInput(''));
        dispatch(setShowErrorMessage(false));
        dispatch(updateUserData({
            specialty: {
                id: selectedOption.id,
                label: selectedOption.label,
                value: selectedOption.value,
                subOptions: {}
            },
            evuid: cookies["evuid"]
        }))
        setError(false);
    };

    const handleSubOptionChange = (selectedOption) => {
        setSelectedSubOption(selectedOption);
        dispatch(updateUserData({
            specialty: {
                id: selectedHealthConcern.id,
                label: selectedHealthConcern.label,
                value: selectedHealthConcern.value,
                subOptions: {
                    id: selectedOption.id,
                    label: selectedOption.label,
                    value: selectedOption.value,
                }
            },
            evuid: cookies["evuid"]
        }))
        setError(false);

    };


    const handleFocus = (fieldName) => {
        setFocusedField(fieldName);
    };
    const customStyles = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: state.isFocused ? '#79ba39' : '#79ba39',
            height: '50px',
            boxShadow: state.isFocused ? '0 0 0 1px #79ba39' : null,
            '&:hover': {
                borderColor: '#79ba39',
            },
        }),
        option: (baseStyles, state) => {

            return {
                ...baseStyles,
                height: '50px',
                backgroundColor: state.isFocused ? '#a5cc7e' : state.isSelected ? '#79ba39' : null,
                '&:hover': {
                    borderColor: '#a5cc7e',
                    backgroundColor: '#a5cc7e',
                },
                color: state.isFocused || state.isSelected ? 'white' : 'black',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
            }
        },
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: 'black',
        }),

    };


    return (
        <>
            <Header progressValue={progressValue} />
            <div className="container page-main1 mt-0" data-aos="fade-left">
                <div className="row">
                    <div className='col-12 mx-auto text-center'>
                        <h3 className='mt-4 '>Tell us what brings you in today</h3>
                    </div>
                </div>
                <div>

                        <p className='mt-3 text-center h6'>Your selections will pair you with our dietitians that are a perfect fit.</p>
                </div>
                <div className="col-lg-12 mt-3 col-md-8 col-sm-12 col-10 mx-auto">
                    <div className="button-container mt-2">

                        <Select
                            options={healthConcersList}
                            value={selectedHealthConcern}
                            onChange={handleHealthConcernChange}
                            placeholder="I’m seeking help with…"
                            styles={customStyles}
                        />


                        {selectedHealthConcern && selectedHealthConcern.subOptions && selectedHealthConcern.subOptions.length > 0 && (
                            <Select
                                options={selectedHealthConcern.subOptions}
                                value={selectedSubOption}
                                onChange={handleSubOptionChange}
                                placeholder="Please specify"
                                styles={customStyles}
                                className={`mt-3 ${error ? 'error-select' :''}`}
                                
                                />
                        )}
                        {selectedHealthConcern?.label === 'Other' && (

                            <input
                                type="text"
                                id="otherInput"
                                className={`form-control mt-4 inputbox ${error ? 'error' :''} ${focusedField === 'otherInput' ? 'selected' : ''}`}
                                onChange={handleAdditionalInfoChange}
                                onBlur={handleAdditionalInfoSave}
                                onFocus={() => handleFocus('otherInput')}
                                value={otherInputValue}
                                placeholder="Please specify"
                            />
                        )}


                    </div>
                </div>


                <div className="col-lg-5 col-md-8 col-sm-12 col-10 mx-auto">
                    {error && <div className="text-danger text-center mt-3">{error}</div>}
                    <div className={error ? "mt-4-5" :"mt-5"}>
                        <button
                            id="nextButton"
                            type="button"
                            className={`next-button ${error ? 'selected' : ''}`}
                            onClick={handleSelectButton}
                        >
                            Continue
                        </button>
                    </div>
                </div>


            </div>

        </>
    );
};

export default EverlongForm;
